import React from "react"
import styled from "styled-components"
import { colors, Container } from "../../theme"
import { ReactComponent as LogoSvg } from "../../images/footer-logo.svg"
import { ReactComponent as MailSvg } from "../../images/mail.svg"
import { ReactComponent as YoutubeSvg } from "../../images/youtube.svg"
import { ReactComponent as InstagramSvg } from "../../images/instagram.svg"
import { graphql, useStaticQuery } from "gatsby"

const FooterContainer = styled.div`
  width: 100%;
  min-height: 256px;
  background: linear-gradient(71.69deg, #717cff 0.86%, #17170a 99.56%);
  display: flex;
  align-items: center;
  flex-direction: column;
`
const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  flex-direction: column;

  svg {
    height: 64px;
    color: white; 
  }
`

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  transition: transform 0.25s ease-in-out;

  a {
    font-family: Manrope;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
    margin-left: 8px;
    text-decoration: none;
  }

  svg {
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
  }
`
const Divider = styled.div`
  width: 100%;
  background: ${colors.white};
  height: 2px;
  opacity: 0.2;
  margin-top: 48px;
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.white};
  }
`

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            id
            ACF_homepage {
              footer {
                email
              }
            }
          }
        }
      }
    }
  `)
  const email = data.allWpPage.edges[0].node.ACF_homepage.footer.email
  const mailto = `mailto:${email}`
  return (
    <FooterContainer>
      <Container>
        <LogoSection>
          <LogoSvg />
          <Contact>
            <MailSvg />
            <a href={mailto}>{email}</a>
          </Contact>
        </LogoSection>
        <Divider />
        <BottomContainer>
          <p>
            &copy; {new Date().getFullYear()} TTO Lithuania. Visos teisės
            saugomos
          </p>
        </BottomContainer>
      </Container>
    </FooterContainer>
  )
}
