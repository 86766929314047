export const colors = {
  gray900: "#18191F",
  blue900: "#543BE3",
  blue100: "#CEE5F2",
  black900: "#1C1D21",
  black800: "#474A57",
  black700: "#969BAB",
  black600: "#D9DBE",
  black500: "#EEEFF4",
  black400: "#F4F5F7",
  yellow900: "#DBDB46",
  yellow100: "#F6FFC2",
  white: "#FFFFFF",
  liner:
    "linear-gradient(180deg, rgba(233, 255, 112, 0.3) 0%, rgba(84, 59, 227, 0.3) 100%)",
  linearGradient: "linear-gradient(71.69deg, #DBDB46 0.85%, #17170A 99.56%)",
  danger: "#FF5959",
}
